import axios from 'axios';
import React, { useState } from 'react'
import ReactGA from 'react-ga4'

const ContactUs = () => {

  ReactGA.send({
    hitType: "pageview",
    page: "/contact-us",
    title: `Contact`,
  });

  const [formSubmitted, setFormSubmitted] = useState('')

  const [formDetails, setFormDetails] = useState({
    leadName: '',
    emailAddress: '',
    interestedService: '',
    message: ''
  })

  const handleFormChange = (e) => {
    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value
    })
    console.log(formDetails);
  }

  const submitForm = async(e) => {
    e.preventDefault()

    if(formDetails.leadName == '' || formDetails.emailAddress == '' || formDetails.message == '') {
      alert("Please fill in the required message information")
      return
    }

    try {
      await axios.post('https://contact-form-email.onrender.com/contact-form/form-submit', {formDetails})
      .then(response => {
        console.log(response);
        setFormSubmitted(response.message)
      })
    }
    catch (error) {
      console.log(error)
      setFormSubmitted(error.message)
    }
    setFormDetails({
      leadName: '',
      emailAddress: '',
      interestedService: '',
      message: ''
    })
  }

  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100 py-12">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
        <div className="text-center mb-6">
          <h2 className="text-5xl font-bold text-gray-900">Contact Us</h2>
          <div className="mt-4 text-gray-600">We're looking forward to hearing from you.</div>
        </div>
        <form className="space-y-4" >
          <div>
            <input
              className="border w-full p-3 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              type="text"
              placeholder="Enter your name"
              name='leadName'
              value={formDetails.leadName}
              onChange={handleFormChange}
            />
          </div>
          <div>
            <input
              className="border w-full p-3 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              type="email"
              placeholder="Enter your email address"
              name='emailAddress'
              value={formDetails.emailAddress}
              onChange={handleFormChange}
            />
          </div>
          <div>
            <select
              name="interestedService"
              value={formDetails.interestedService}
              onChange={handleFormChange}
              className="border w-full p-3 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="sms">SMS Ads</option>
              <option value="email">Email Marketing</option>
              <option value="lead_gen">Lead Generation</option>
              <option value="all_services">All of the above</option>
            </select>
          </div>
          <div>
            <textarea
              className="border w-full p-3 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Tell us a bit about your business needs"
              rows="4"
              name='message'
              value={formDetails.message}
              onChange={handleFormChange}
            />
          </div>
          <div>
            <button
              type="submit"
              onClick={submitForm}
              className="w-full py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 font-medium"
            >
              Send
            </button>
          </div>
        </form>
        {formSubmitted && <p>{formSubmitted}</p>}
      </div>
    </div>
  )
}

export default ContactUs
