import React from 'react'
import { useParams } from 'react-router-dom'
import blogs from '../../blogs';
import ReactGA from 'react-ga4'

const BlogPost = () => {

    const {id} = useParams()
    const blogId = parseInt(id);

    ReactGA.send({
      hitType: "pageview",
      page: `/blog/${id}`,
      title: `Blog- ${id}`,
    });
    
    const blog = blogs[blogId]

  return (
    <div className="py-12">
        <div className="max-w-3xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>
            <div className=" my-4" dangerouslySetInnerHTML={{ __html: blog.content }}/>
        </div>
    </div>
  )
}

export default BlogPost