import { Routes, Route } from 'react-router-dom';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import Landing from './pages/landing/Landing';
import Resources from './pages/resources/Resources';
import Pricing from './pages/pricing/Pricing';
import ContactUs from './pages/contact-us/ContactUs';
import ComingSoon from './pages/coming-soon/ComingSoon';
import BlogPost from './pages/resources/BlogPost';
import AboutUs from './pages/about/AboutUs';
import ReactGA from 'react-ga4'

function App() {

  ReactGA.initialize("G-7N1091P1GT")

  ReactGA.send({
    hitType: "pageview",
    page: `${window.location.href}`,
    title: `${window.location.href}`,
  });

  return (
    <div className="App ">
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Landing/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route exact path="/blog" element={<Resources/>} />
        <Route exact path="/blog/:id" element={<BlogPost/>} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
