import React, { useState } from 'react'
import { IoAdd } from "react-icons/io5";

const FAQs = () => {
    const [toggleItem, setToggleItem] = useState(null)

    const toggleAccordionItem = (id) => {
        setToggleItem((prevState) => (prevState === id ? null : id))
    }

    return (
        <div className="bg-white py-4 lg:py-6 mb-10">
            <div className='md:max-w-[90%] m-auto'>
                <div className='text-center m-10'>
                    <p className='text-4xl font-bold mb-2'>Frequently Asked Questions</p>
                    <p>Learn more about our done-for-you SMS marketing services</p>
                </div>
                <div className="mx-auto max-w-[80%] grid gap-5 p-6 rounded-xl">
                    <div className='border-b border-slate-400 border-spacing-4 pb-3'>
                        <div className='cursor-pointer flex justify-between items-center' onClick={() => toggleAccordionItem(1)}>
                            <p className='text-indigo-600 text-2xl'>What is done-for-you SMS marketing?</p>
                            <IoAdd className='text-2xl text-indigo-600'/>
                        </div>
                        <div className={`overflow-hidden transition-all duration-300 ${toggleItem === 1 ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'}`}>
                            <p className='text-gray-500 mt-2'>Done-for-you SMS marketing is a service where we handle all aspects of your SMS campaigns, from strategy and content creation to execution and analysis. You provide us with your objectives, and we take care of the rest.</p>
                        </div>
                    </div>
                    <div className='border-b border-slate-400 border-spacing-4 pb-3'>
                        <div className='cursor-pointer flex justify-between items-center' onClick={() => toggleAccordionItem(2)}>
                            <p className='text-indigo-600 text-2xl'>How can bulk SMS services benefit my business?</p>
                            <IoAdd className='text-2xl text-indigo-600'/>
                        </div>
                        <div className={`overflow-hidden transition-all duration-300 ${toggleItem === 2 ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'}`}>
                            <p className='text-gray-500 mt-2'>Bulk SMS services allow you to reach a large audience instantly, providing a high open rate compared to emails. This can increase customer engagement, drive sales, promote events, and enhance overall customer communication.</p>
                        </div>
                    </div>
                    <div className='border-b border-slate-400 border-spacing-4 pb-3'>
                        <div className='cursor-pointer flex justify-between items-center' onClick={() => toggleAccordionItem(3)}>
                            <p className='text-indigo-600 text-2xl'>What kind of support do you offer?</p>
                            <IoAdd className='text-2xl text-indigo-600'/>
                        </div>
                        <div className={`overflow-hidden transition-all duration-300 ${toggleItem === 3 ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'}`}>
                            <p className='text-gray-500 mt-2'>We offer comprehensive support including campaign strategy, content creation, scheduling, and performance analysis. Our team is available to answer any questions and provide assistance throughout your campaign.</p>
                        </div>
                    </div>
                    <div className='border-b border-slate-400 border-spacing-4 pb-3'>
                        <div className='cursor-pointer flex justify-between items-center' onClick={() => toggleAccordionItem(4)}>
                            <p className='text-indigo-600 text-2xl'>Can I track the performance of my SMS campaigns?</p>
                            <IoAdd className='text-2xl text-indigo-600'/>
                        </div>
                        <div className={`overflow-hidden transition-all duration-300 ${toggleItem === 4 ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'}`}>
                            <p className='text-gray-500 mt-2'>Yes, we provide detailed reports on the performance of your SMS campaigns, including delivery rates, open rates, and any user interactions. This helps you understand the effectiveness of your campaigns and make data-driven decisions.</p>
                        </div>
                    </div>
                    <div className='border-b border-slate-400 border-spacing-4 pb-3'>
                        <div className='cursor-pointer flex justify-between items-center' onClick={() => toggleAccordionItem(5)}>
                            <p className='text-indigo-600 text-2xl'>Is my customer data secure?</p>
                            <IoAdd className='text-2xl text-indigo-600'/>
                        </div>
                        <div className={`overflow-hidden transition-all duration-300 ${toggleItem === 5 ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'}`}>
                            <p className='text-gray-500 mt-2'>Absolutely. We prioritize data security and ensure that all customer data is handled in compliance with data protection regulations. Your data is stored securely and only used for the purposes of your campaigns.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQs;
