import React from 'react'
import HeroImg from '../../assets/Email campaign-cuate.png'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className="lg:min-h-[87vh] bg-gradient-to-tl from-indigo-300 via-indigo-100 to-white grid grid-cols-2 p-3 items-center content-center">
        <div className="mx-auto gap-6 flex flex-col content-center justify-center p-2 lg:max-w-xl md:col-span-1 col-span-2">
            <div className="mb-4 my-4 md:my-1">
                <p className="xl:text-6xl lg:text-5xl font-bold mb-4"><span className="text-indigo-600">Growing</span> Your Business with <span className="text-indigo-600">SMS</span> & <span className="text-indigo-600">Email</span> Marketing</p>
                <p>Boost your revenue and strengthen customer connections with our tailored outreach solutions. We amplify your campaigns while you focus on scaling your business.</p>
            </div>
            <div className="">
                <p className="mb-4 italic">Join today and enjoy lifetime access with a one-time payment.</p>
                <Link to='/join-now'>
                    <button className='mb-4 py-2 px-5 border border-indigo-800 bg-indigo-600 rounded-3xl text-white hover:text-indigo-800 hover:bg-white'>
                        <Link to='/contact-us'>CLAIM LIMITED OFFER</Link>
                    </button>
                </Link>
                <p className="text-xs">Offer valid until July 30, 2024. Don't miss out!</p>
            </div>
        </div>
        <div className="md:col-span-1 col-span-2">
            <img src={HeroImg} alt="Automated Marketing Campaigns" className='lg:w-[80%] w-[90%] mx-auto' />
        </div>
    </div>
  )
}

export default Hero
