import React from 'react'
import blogs from '../../blogs'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

const Resources = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/blog",
    title: `Blog`,
  })

  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto text-center mb-8">
        <h2 className="text-5xl font-bold text-gray-900">Blog</h2>
        {/* <p className="mt-4 text-gray-600">Choose a plan that suits your business needs.</p> */}
      </div>

      <div className="grid lg:grid-cols-3 gap-6 max-w-7xl mx-auto p-4">
        {blogs.map((blog, index) => (
          <div key={index} className="bg-white rounded-lg overflow-hidden shadow-md">
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
              <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: `${blog.content.substring(0, 180)}...`}}/>
            </div>
            <div className="p-4 bg-gray-100 border-t border-gray-200">
              <Link to={`/blog/${index}`} className="text-indigo-600 font-medium">Read More</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Resources