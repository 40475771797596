import React from 'react';
import { GoArrowUpRight } from "react-icons/go";

const ThreeBlocks = () => {
  return (
    <div className='w-full bg-gray-100 p-6 lg:h-[480px]'>
      <div className='grid lg:grid-cols-3 gap-4 justify-center items-center content-center m-auto md:w-[65%] h-full'>
        
        {/* Block 1: Instant Connection */}
        <div className='p-5 col-span-1 rounded-[25px] bg-white shadow-xl w-full md:min-h-[300px] relative'>
          <div className='flex justify-center bg-slate-100 rounded-xl mb-3'>
            <GoArrowUpRight className='text-3xl m-2'/>
          </div>
          <p className="my-4 font-semibold text-lg">Forge Instant Connections</p>
          <p>
            Seamlessly connect with new and potential customers directly, fostering stronger relationships with personalized outreach messages.
          </p>
        </div>
        
        {/* Block 2: Enhanced Engagement */}
        <div className='p-5 col-span-1 rounded-[25px] bg-slate-900 shadow-xl h-full w-full md:min-h-[350px] text-white'>
          <div className='flex justify-center bg-slate-100 rounded-xl mb-3 text-black'>
            <GoArrowUpRight className='text-3xl m-2'/>
          </div>
          <p className="my-4 font-semibold text-lg">Boost Engagement Levels</p>
          <p>
            Elevate customer engagement through tailored messaging strategies, ensuring your brand stays top-of-mind for increased conversions and loyalty.
          </p>
        </div>
        
        {/* Block 3: Simplified Automation */}
        <div className='p-5 col-span-1 rounded-[25px] bg-white shadow-xl w-full md:min-h-[300px]'>
          <div className='flex justify-center bg-slate-100 rounded-xl mb-3'>
            <GoArrowUpRight className='text-3xl m-2'/>
          </div>
          <p className="my-4 font-semibold text-lg">Streamlined Automation</p>
          <p>
            Effortlessly run fully automated campaigns, freeing up your time while ensuring consistent messaging and engagement with your audience.
          </p>
        </div>
      
      </div>
    </div>
  )
}

export default ThreeBlocks;
