import React from 'react';
import Comments from '../../assets/comments-mock.PNG';
import Graph from '../../assets/graph-mock.PNG';
import Contacts from '../../assets/contacts-mock.PNG';
import Orders from '../../assets/orders-mock.PNG';

const EssentialTools = () => {
  return (
    <div className='w-full bg-white md:py-4 lg:py-6 mb-10'>
      <div className='md:max-w-[90%] m-auto'>
        <div className='text-center m-10'>
          <p className='text-4xl font-bold mb-2'>Essential Tools for Growth</p>
          <p>Unlock the potential of outreach automation for your whole business</p>
        </div>
        <div className='grid lg:grid-cols-2 xs:grid-cols-1 lg:mx-10 mx-6 gap-6'>
          
          {/* Block 1: Lead Generation */}
          <div className='col-span-1 rounded-[35px] p-8 bg-indigo-100'>
            <div className='mb-3'>
              <p className="font-bold text-xl">Automated Lead Generation</p>
            </div>
            <div className='mb-5'>
              <p className='font-semibold'>Effortlessly gather and segment leads for targeted marketing. Our service helps you build and maintain high-quality lead lists.</p>
            </div>
            <div className='relative w-fit m-auto'>
              <div className='w-content'>
                <img src={Graph} alt="Automated Lead Generation" className="block max-h-[250px] rounded-lg" /> 
              </div>
            </div>
          </div>
          
          {/* Block 2: Bulk SMS Messaging */}
          <div className='col-span-1 rounded-[35px] p-8 bg-indigo-100'>
            <div className='mb-3'>
              <p className="font-bold text-xl">Effective Bulk SMS Messaging & Email Marketing</p>
            </div>
            <div className='mb-5'>
              <p className='font-semibold'>Send bulk SMS messages with personalized content to engage your audience. Drive conversions with precise and targeted messaging.</p>
            </div>
            <div className='relative w-fit m-auto'>
              <div className=''>
                <img src={Contacts} alt="Effective Bulk SMS Messaging" className="block max-h-[250px] rounded-lg" /> 
              </div>
            </div>
          </div>
          
          {/* Block 3: Sales and Revenue Growth */}
          <div className='col-span-1 rounded-[35px] p-8 bg-indigo-100'>
            <div className='mb-3'>
              <p className="font-bold text-xl">Boost Sales and Revenue</p>
            </div>
            <div className='mb-5'>
              <p className='font-semibold'>Increase your sales and revenue with our strategic SMS and Email campaigns. Deliver timely promotions and offers directly to your customers' phones.</p>
            </div>
            <div className='relative w-fit m-auto'>
              <div className=''>
                <img src={Orders} alt="Boost Sales and Revenue" className="block max-h-[250px] rounded-lg" /> 
              </div>
            </div>
          </div>
          
          {/* Block 4: Brand Awareness */}
          <div className='col-span-1 rounded-[35px] p-8 bg-indigo-100'>
            <div className='mb-3'>
              <p className="font-bold text-xl">Enhance Brand Awareness</p>
            </div>
            <div className='mb-5'>
              <p className='font-semibold'>Expand your reach and increase brand visibility with impactful SMS and Email marketing campaigns. Engage more potential customers effortlessly.</p>
            </div>
            <div className='relative w-fit m-auto'>
              <div className=''>
                <img src={Comments} alt="Enhance Brand Awareness" className="block max-h-[250px] rounded-lg" /> 
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  )
}

export default EssentialTools;
