import React from 'react';
import ReactGA from 'react-ga4'

const AboutUs = () => {
  
  ReactGA.send({
    hitType: "pageview",
    page: "/about",
    title: `About`,
  });

  return (
    <div className="w-full min-h-screen bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-5xl font-bold text-gray-900">About Us</h2>
          <p className="mt-4 text-gray-600">Learn more about our journey and mission</p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Our Story</h3>
          <p className="text-gray-600 mb-4">
            Our journey began with a simple idea: to help businesses connect with their audience in the most efficient and effective way possible. With years of experience in the digital marketing industry, we recognized the growing need for personalized communication and the power it holds in driving customer engagement and sales.
          </p>
          <p className="text-gray-600 mb-4">
            From our humble beginnings, we've grown into a full-fledged SMS and email marketing agency, serving clients across various industries. Our team of dedicated professionals works tirelessly to create and execute marketing strategies that resonate with our clients' target audiences and drive measurable results.
          </p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Our Mission</h3>
          <p className="text-gray-600 mb-4">
            Our mission is simple: to empower businesses with the tools and expertise they need to effectively reach and engage their customers. We believe in the power of communication and are committed to helping our clients leverage SMS and email marketing to grow their businesses.
          </p>
          <p className="text-gray-600 mb-4">
            We aim to provide top-notch, personalized marketing solutions that not only meet but exceed our clients' expectations. By staying at the forefront of industry trends and technologies, we ensure that our clients always have a competitive edge.
          </p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Why Choose Us?</h3>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>Expertise: Our team has extensive experience and knowledge in SMS and email marketing.</li>
            <li>Personalized Solutions: We tailor our services to meet the unique needs of each client.</li>
            <li>Proven Results: Our strategies are data-driven and have been proven to deliver results.</li>
            <li>Customer-Centric: We prioritize our clients' needs and work closely with them to achieve their goals.</li>
            <li>Innovative Approach: We stay updated with the latest trends and technologies to provide cutting-edge solutions.</li>
          </ul>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Meet the Team</h3>
          <p className="text-gray-600 mb-4">
            Our team is comprised of passionate professionals who are experts in their respective fields. From digital marketers to content creators, we work collaboratively to deliver exceptional results for our clients.
          </p>
          <p className="text-gray-600 mb-4">
            We're more than just a team – we're a family. We believe in fostering a positive and supportive work environment where creativity and innovation can thrive. Get to know the people behind our success and see why we're so committed to helping your business grow.
          </p>
        </div>
        <div className="text-center">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Join Us on Our Journey</h3>
          <p className="text-gray-600 mb-4">
            We invite you to join us on our journey to revolutionize the way businesses connect with their customers. Together, we can achieve great things.
          </p>
          <p className="text-gray-600 mb-4">
            Contact us today to learn more about how we can help your business thrive with our SMS and email marketing solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
