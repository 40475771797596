import React from 'react'
import Hero from '../../components/hero/Hero'
import Partners from '../../components/partners/Partners'
import ThreeBlocks from '../../components/three-blocks/ThreeBlocks'
import EssentialTools from '../../components/essential-tools/EssentialTools'
import FAQs from '../../components/faqs/FAQs'
import CaseStudies from '../../components/case-studies/CaseStudies'
import ReactGA from 'react-ga4'

const Landing = () => {


  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: `Home`,
  });
  return (
    <div className="">
        <Hero />

        <Partners/>

        <ThreeBlocks />

        <EssentialTools />

        <CaseStudies/>

        <FAQs />

    </div>
  )
}

export default Landing